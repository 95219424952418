import { devices } from '@/utils/devices'

export default {
	name: 'global',
	store() {
		return {
			isMinicartVisible: false,
			isSearchVisible: false,
			device: devices[0].name,
			init() {
				this.initLiquidAJaxCart()
				this.setDevice()
				// TODO: Figure out how to use this.$useWindowResize() here
				window.addEventListener('resize', this.setDevice, { passive: true })
			},
			initLiquidAJaxCart() {
				document.addEventListener('liquid-ajax-cart:request-end', event => {
					const { requestState, cart } = event.detail

					if (requestState.requestType === 'add') {
						if (requestState.responseData?.ok) {
							this.isMinicartVisible = true
						}
					}

					this.cart = cart
				})
			},
			setDevice() {
				const vw = window.innerWidth
				const currentDevice = devices.find(d => d.breakpoint < vw)?.name || devices[0].name
				this.device = currentDevice
			}
		}
	}
}
