/* eslint-disable @typescript-eslint/naming-convention */
declare global {
	interface Window {
		Shopify
		dataLayer
	}
}

export function init() {
	const ctas = document.querySelectorAll('a[data-tag],button[data-tag],a[data-item]')
	ctas.forEach(cta => {
		cta.addEventListener('click', e => {
			const target = e.currentTarget as HTMLElement
			const item = target.dataset.item

			const o = {}
			let event = 'cta_clicked'

			if (item) {
				o.items = [JSON.parse(item)]
				if (target.dataset.list) o.item_list_name = target.dataset.list
				event = 'item_selected'
			} else {
				o.clic_cta = target.dataset.tag
				if (target.href) o.href = target.href

				const menu_element = target.dataset.menu
				if (menu_element) o.menu_element = menu_element
			}

			window.Shopify.analytics.publish(event, o)
		})
	})
}
